import * as React from 'react';
import Link from 'next/link';
import { Controller, useForm } from 'react-hook-form';
import { useSelector, useDispatch } from 'react-redux';

import { yupResolver } from '@hookform/resolvers/yup';

import {
  OrganizationInfoType,
  setOrganizationReset,
} from 'reducers/organizationSlice';
import { RootState } from 'store';
import {
  useGetOrganizationQuery,
  useLazyGetUserQuery,
  useLoginMutation,
} from 'store/api';

import Form from 'components/form';

import Button from 'ui-kit/button';
import { ButtonType } from 'ui-kit/button/type';
import Input from 'ui-kit/input';
import { getAuthorizationSchema } from 'utils/schema';

import { LoginValuesType, LoginValuesTypeAndOrg } from 'types/authorization';
import { RegistrationInputNamesEnum } from 'types/registration';

import { initialLogin, LOGIN_ERRORS, TYPE_NAME_LOGIN } from '../constants';

import styles from './login.module.scss';
import { useRouter } from 'next/router';
import { Links } from 'types/links';
import { createToastError } from 'ui-kit/alert-info/controls';
import { lexics } from 'data/lexics';

const Login = () => {
  const router = useRouter();
  const dispatch = useDispatch();
  const { name, id } = useSelector<RootState, OrganizationInfoType>(
    ({ organization }) => organization,
  );

  const [orgNoMatch, setOrgNoMatch] = React.useState(false);
  const { data } = useGetOrganizationQuery(name!, {
    skip: !name,
  });

  const [fetchLogin, { isLoading }] = useLoginMutation();
  const [fetchUser] = useLazyGetUserQuery();

  const { handleSubmit, setError, control } = useForm({
    mode: 'onBlur',
    reValidateMode: 'onBlur',
    defaultValues: initialLogin,
    resolver: yupResolver(
      getAuthorizationSchema(data?.allowedEmailDomains ?? [''], name),
    ),
  });

  const checkAccessAndRedirect = async (): Promise<void> => {
    await fetchUser()
      .unwrap()
      .then((response) => {
        if (
          (response?.learningAccess &&
            router.query?.redirect?.includes('learning')) ||
          (response?.detailingAccess &&
            router.query?.redirect?.includes('detailing'))
        ) {
          window.location.assign(`http://${router.query?.redirect}`);
        } else {
          router.replace(Links.home);
        }
      })
      // eslint-disable-next-line no-console
      .catch((e) => console.log(e));
  };

  const onSubmit = async (data: LoginValuesType) => {
    const augmentedUserData: LoginValuesTypeAndOrg = {
      ...data,
      organisationId: id,
    };
    if (isLoading) return;
    await fetchLogin(augmentedUserData)
      .unwrap()
      .then(checkAccessAndRedirect)
      .catch((error) => {
        // заблокирован
        if (error.status === 402) {
          createToastError(`${lexics.api.response.login.denied} ${name}`);
          return;
        }
        // нет такого юзера
        if (error?.status === 404) {
          setError(
            RegistrationInputNamesEnum.email,
            {
              message: LOGIN_ERRORS.EMAIL_NOT_FOUND,
              type: TYPE_NAME_LOGIN,
            },
            { shouldFocus: true },
          );
        }
        // неподтвержденная почта
        if (error.data.message.includes('подтвердить')) {
          setError(
            RegistrationInputNamesEnum.email,
            {
              message: LOGIN_ERRORS.EMAIL_INACTIVE,
              type: TYPE_NAME_LOGIN,
            },
            { shouldFocus: true },
          );
          return;
        }

        if (error.data.message.includes('организация')) {
          setError(RegistrationInputNamesEnum.password, {
            message: LOGIN_ERRORS.ORGANIZATION_NO_MATCH,
            type: TYPE_NAME_LOGIN,
          });
          setOrgNoMatch(true);
          return;
        }
        if (error?.status === 403) {
          //неверный пароль
          setError(
            RegistrationInputNamesEnum.password,
            {
              message: LOGIN_ERRORS.NOT_MATCH,
              type: TYPE_NAME_LOGIN,
            },
            { shouldFocus: true },
          );
        }
      });
  };

  const onReset = () => {
    dispatch(setOrganizationReset());
    router.replace(Links.organization);
  };

  return (
    <div className={styles.authorization}>
      <Form onSubmit={handleSubmit(onSubmit)} className={styles.form}>
        <p className={styles.title}>Вход</p>
        <Controller
          name={RegistrationInputNamesEnum.email}
          control={control}
          render={({ field, fieldState }) => (
            <Input
              readonly={orgNoMatch}
              placeholder="Введите логин"
              type="email"
              label="Логин"
              autoComplete="on"
              className={styles.input}
              fieldState={fieldState}
              {...field}
            />
          )}
        />
        <Controller
          name={RegistrationInputNamesEnum.password}
          control={control}
          render={({ field, fieldState }) => (
            <Input
              readonly={orgNoMatch}
              placeholder="Введите пароль"
              type="password"
              label="Пароль"
              autoComplete="current-password"
              className={styles.input}
              fieldState={fieldState}
              {...field}
            />
          )}
        />
        <div className={styles.options}>
          <Link href={Links.resetPassword}>Забыли пароль?</Link>
        </div>
        {orgNoMatch ? (
          <Button
            type={ButtonType.button}
            onClick={onReset}
            className={styles.button}>
            Изменить организацию
          </Button>
        ) : (
          <Button
            type={ButtonType.submit}
            loading={isLoading}
            className={styles.button}>
            Войти
          </Button>
        )}
      </Form>
    </div>
  );
};

export default Login;
